const bubble = async (array, swap, compare, update, markSorted) => {
  // let count = 0;
  for (let i = array.length - 1; i >= 0; i--) {
    let success = true
    for (let j = 0; j < i; j++) {
      if (compare(array, j, j + 1) > 0) {
        swap(array, j, j + 1)
        success = false
      }

      // if ((count++) % 5 === 0)
      await update()
    }
    markSorted(i)

    if (success) return array
  }

  return array
}

export default bubble
