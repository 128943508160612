import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/Layout'
import Meta from 'components/Meta'
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps'
import styled from 'styled-components'

const StyledAddress = styled.address`
  white-space: pre-wrap;
`

const MapWithAMarker = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={7}
      defaultCenter={{ lat: 46.076216, lng: 14.473481 }}
    >
      <Marker position={{ lat: 46.076216, lng: 14.473481 }} />
    </GoogleMap>
  ))
)

class Contact extends React.Component {
  render() {
    const { location, data } = this.props
    const logo = get(data, 'logo.childImageSharp.sizes')
    const wide3 = get(data, 'wide3.childImageSharp.sizes')

    return (
      <Layout location={location}>
        <Meta
          site={siteMetadata}
          title="While true d.o.o "
          googleMapsSrc={true}
        />
        <div>
          <section
            className="text-center"
            style={{
              background: `url(${wide3.src}) no-repeat center center scroll`,
              backgroundSize: 'cover',
            }}
          >
            <img
              className={'img-responsive img-center'}
              style={{ maxWidth: '100%' }}
              src={logo.src}
            />
          </section>

          <section id="repos" className="text-center 3">
            <div className="container" style={{ paddingTop: '2rem' }}>
              <div className="row align-items-center">
                <div className="col-md-12 text-left">
                  <h1 className="section-heading">Contact</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <p style={{ textAlign: 'justify' }}>
                    <b>Headquarters (mail address)</b>: {'\u00A0'}
                    <StyledAddress>
                      Partizanska cesta 43
                      <br />
                      4220 Škofja Loka
                      <br />
                      Slovenia
                      <br />
                      EU
                    </StyledAddress>
                    <br />
                    <b>Office</b>:
                    <StyledAddress>
                      Vodnikova cesta 211
                      <br />
                      1000 Ljubljana
                      <br />
                      Slovenia
                      <br />
                      EU
                    </StyledAddress>
                    <br />
                    <b>VAT ID</b>:{'\u00A0'} SI84824239
                    <br />
                    <b>Contact email:</b>
                    <a href="mailto:info@whiletrue.com">
                      {'\u00A0'}info@whiletrue.com
                    </a>
                    <br />
                    <b>Business inquiries:</b>
                    <a href="mailto:gorazd.breskvar@whiletrue.com">
                      {'\u00A0'}gorazd.breskvar@whiletrue.com
                    </a>
                    <br />
                    <b>LinkedIn:</b>
                    <a href="https://www.linkedin.com/in/gorazdbreskvar/">
                      {'\u00A0'}https://www.linkedin.com/in/gorazdbreskvar/
                    </a>
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="text-center" id="jobs">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h1
                    className="section-heading"
                    style={{ textAlign: 'justify' }}
                  >
                    Careers
                  </h1>
                  <p style={{ textAlign: 'justify' }}>
                    We are looking for talented software engineers and front-end
                    designers. <br />
                    Contact us at:{' '}
                    <a href="mailto:jobs@whiletrue.com">jobs@whiletrue.com</a>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section id="repos" className="text-center bg-white text-black-50">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-12 text-left">
                  <h2 className="section-heading">Office</h2>
                </div>
              </div>
              <div className="row align-items-center">
                <MapWithAMarker
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCyqEA_7BwKnFJpxYPs42ymgPpElxLxII8&v=3.exp&libraries=geometry,drawing,places"
                  loadingElement={
                    <div style={{ height: `100%`, width: '100%' }} />
                  }
                  containerElement={
                    <div style={{ height: `300px`, width: '100%' }} />
                  }
                  mapElement={<div style={{ height: `100%`, width: '100%' }} />}
                />
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Contact

export const query = graphql`
  query ContactPageQuery {
    logo: file(name: { eq: "logo" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
    wide3: file(name: { eq: "wide3" }) {
      childImageSharp {
        sizes(quality: 100) {
          ...GatsbyImageSharpSizes_withWebp
        }
      }
    }
  }
`
