import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import bubbleSort from './bubble-sort'

const SORTED = 128

const MAX_VALUE = 500

const SortCanvas = styled.div`
  display: flex;
  text-align: center;

  background-color: ${props => props.backgroundColor};
  justifycontent: center;
  width: 100%;
  height: 100%;
`

const SortItem = styled.div`
  ${props => `width:${props.width}`};
  background-color: green;
  align-self: flex-end;
  ${props => `margin-left:${props.marginLeft}`};
  transition: 0.1s linear;
`

class Sort extends React.Component {
  constructor(props) {
    super(props)
    const arr = this.randomArray()
    this.state = {
      array: arr,
      moves: new Array(arr.length),
      swaps: 0,
      compares: 0,
    }
  }

  swap(map, array, x, y) {
    const tmp = array[x]
    array[x] = array[y]
    array[y] = tmp
    map[x] |= 1
    map[y] |= 1
    this.swaps++
  }

  markSorted(map, x) {
    map[x] |= SORTED
  }

  compare(map, array, x, y) {
    map[x] |= 2
    map[y] |= 2
    this.compares++
    return array[x] - array[y]
  }

  randomArray() {
    const array = []
    for (let i = 0; i < this.props.elements; i++) {
      array.push(Math.ceil(Math.random() * MAX_VALUE))
    }
    return array
  }

  random() {
    const arr = this.randomArray()
    this.setState({
      array: arr,
      moves: new Array(arr.length),
    })
  }

  reverse() {
    const arr = [...this.state.array].reverse()
    this.setState({
      array: arr,
      moves: new Array(arr.length),
    })
  }

  range() {
    const arr = new Array(this.props.elements)
    for (let i = 0; i < arr.length; i++) arr[i] = i % MAX_VALUE
    this.setState({
      array: arr,
      moves: new Array(arr.length),
    })
  }

  async componentDidMount() {
    await this.random()
    await this.sort()
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  async update() {
    await this.setState({
      moves: [...this.map],
      array: [...this.array],
      swaps: this.swaps,
      compares: this.compares,
    })
    for (let i = 0; i < this.map.length; i++)
      this.map[i] = this.map[i] >= SORTED ? SORTED : 0
    await this.timeout(this.props.delay)
  }

  movesToColor(v) {
    if (v === 0) return this.props.unsortedColor
    else if (v === 1) return this.props.defaultColor
    else if (v === 2) return this.props.defaultColor
    else if (v >= SORTED) return this.props.sortedColor
    return this.props.defaultColor
  }

  async bubbleSort() {
    this.map = new Array(this.state.array.length)
    this.array = [...this.state.array]
    this.compares = 0
    this.swaps = 0
    const sorted = await bubbleSort(
      this.array,
      this.swap.bind(this, this.map),
      this.compare.bind(this, this.map),
      this.update.bind(this),
      this.markSorted.bind(this, this.map)
    )
    await this.setState({
      array: sorted,
      moves: [...this.map.fill(SORTED)],
      swaps: this.swaps,
      compares: this.compares,
    })
  }

  async sort() {
    if (this.props.sort === 'bubble') await this.bubbleSort()
  }

  render() {
    return (
      <div
        style={{ width: this.props.width, height: this.props.height }}
        onClick={async () => {
          await this.random()
          await this.sort()
        }}
      >
        <SortCanvas backgroundColor={this.props.backgroundColor}>
          {this.state.array.map((x, i) => (
            <SortItem
              key={i}
              width={`${this.props.cellWidth}px`}
              marginLeft={`${this.props.marginLeft}px`}
              style={{
                height: `${(x * 100.0) / MAX_VALUE}%`,
                backgroundColor: this.movesToColor(this.state.moves[i]),
              }}
            />
          ))}
        </SortCanvas>
        {/*
        <button onClick={this.random.bind(this)}>Random</button>
        <button onClick={this.reverse.bind(this)}>Reverse</button>
        <button onClick={this.range.bind(this)}>Range</button>
        <button onClick={this.sort.bind(this)}>Bubble sort</button>
        <span>Elements: {this.state.array.length} Compares: {this.state.compares} Swaps: {this.state.swaps}</span>
*/}
      </div>
    )
  }
}

Sort.defaultProps = {
  backgroundColor: '#444',
  sortedColor: 'green',
  unsortedColor: 'gray',
  defaultColor: 'red',
}

Sort.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  elements: PropTypes.number.isRequired,
  delay: PropTypes.number.isRequired,
  cellWidth: PropTypes.number.isRequired,
  marginLeft: PropTypes.number.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  sortedColor: PropTypes.string.isRequired,
  unsortedColor: PropTypes.string.isRequired,
  defaultColor: PropTypes.string.isRequired,
  sort: PropTypes.oneOf(['bubble', 'insert']).isRequired,
}

export default Sort
