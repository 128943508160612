import React from 'react'
import { Link } from 'gatsby'
import Sort from '../fun/sort'

const renderSort = width => (
  <Sort
    height={30}
    cellWidth={3}
    elements={30}
    {...width}
    delay={50}
    marginLeft={2}
    sortedColor={'#F38001'}
    backgroundColor={'#222'}
    unsortedColor={'gray'}
    defaultColor={'white'}
    sort={'bubble'}
  />
)
const renderTitle = mobile => (
  <div className={mobile ? 'pl-2 pt-1' : 'pl-2'}>While True</div>
)

class Navi extends React.Component {
  render() {
    const { location, title } = this.props
    return (
      <nav className="navbar navbar-expand navbar-dark flex-column flex-md-row bg-primary">
        <div className="d-none d-md-block">{renderTitle(false)}</div>
        <div className="d-flex d-md-none row justify-content-between w-100">
          {renderTitle(true)}
          {renderSort(0)}
        </div>

        <div className="container">
          <div className="navbar-collapse collapse w-100 order-3 dual-collapse2">
            <ul className="navbar-nav ">
              <li
                className={
                  location.pathname === '/' ? 'nav-item active' : 'nav-item'
                }
              >
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              {/*<li*/}
              {/*  className={*/}
              {/*    location.pathname === '/blog/'*/}
              {/*      ? 'nav-item active'*/}
              {/*      : 'nav-item'*/}
              {/*  }*/}
              {/*>*/}
              {/*  <Link to="/blog/" className="nav-link">*/}
              {/*    Technical Blog*/}
              {/*  </Link>*/}
              {/*</li>*/}
              <li
                className={
                  location.pathname === '/contact/'
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <Link to="/contact/" className="nav-link">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="navbar-nav flex-row ml-md-auto d-none d-md-flex" />
        </div>
        <div className="d-none d-md-block">{renderSort(200)}</div>
      </nav>
    )
  }
}

export default Navi
