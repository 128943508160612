import React from 'react'
import styled from 'styled-components'

const FooterDiv = styled.div`
  margin-top: 0.5em;
`

const Footer = ({ author, title }) => (
  <FooterDiv>
    <div className="container text-center">
      <p>Copyright © {new Date().getFullYear()} While True, d.o.o</p>
    </div>
  </FooterDiv>
)

export default Footer
